import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { inject, makeStateKey, Provider, TransferState } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { relayStylePagination } from '@apollo/client/utilities';
import { environment } from '@env';
import { RetryLink } from '@apollo/client/link/retry';

const APOLLO_STATE_KEY = makeStateKey<any>('apollo.state');

function onServer(transferState: TransferState, cache: InMemoryCache) {
  transferState.onSerialize(APOLLO_STATE_KEY, () => {
    return cache.extract();
  });
}

function onBrowser(transferState: TransferState, cache: InMemoryCache) {
  const state = transferState.get<any>(APOLLO_STATE_KEY, null);
  cache.restore(state);
}

export function apolloOptionsFactory(
  transferState: TransferState,
): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 3000,
      jitter: true,
    },
    attempts: {
      max: 10,
    },
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          genres: relayStylePagination(),
          tags: relayStylePagination(),
          eventTypes: relayStylePagination(),
          events: relayStylePagination(),
          featuredEvents: relayStylePagination(),
        },
      },
    },
  });

  const isBrowser = transferState.hasKey<any>(APOLLO_STATE_KEY);

  if (isBrowser) {
    onBrowser(transferState, cache);
  } else {
    onServer(transferState, cache);
  }

  return {
    link: ApolloLink.from([
      retryLink,
      httpLink.create({ uri: environment.apiUrl }),
    ]),
    cache,
    ssrMode: true,
    ssrForceFetchDelay: 100,
  };
}

export const provideApollo = (): Provider => [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [TransferState],
  },
];

<div
  class="fixed top-0 z-10 flex w-full flex-wrap items-center justify-between border-0 border-b border-gray-200 bg-white px-2 py-3 lg:px-20"
>
  <div class="flex gap-5">
    <img
      ngSrc="assets/images/logo.svg"
      priority
      class="h-11 w-auto cursor-pointer"
      routerLink="/"
      width="812"
      height="275"
      alt="Events 069 Logo"
    />
    <!--    <app-dropdown class="w-20">-->
    <!--      <app-dropdown-item>-->
    <!--        <svg-icon src="assets/icons/de.svg"></svg-icon>-->
    <!--      </app-dropdown-item>-->
    <!--    </app-dropdown>-->
  </div>
  <app-button
    (click)="toggleMenu()"
    class="lg:!hidden"
    color="gray"
    iconLeft="list"
  ></app-button>
  <div
    [style.max-height.px]="isMenuOpen() ? 300 : 0"
    [ngClass]="{
      'py-8 opacity-100': isMenuOpen()
    }"
    class="flex w-full flex-col items-center justify-center gap-4 overflow-hidden opacity-0 transition-all lg:flex lg:!max-h-full lg:w-fit lg:flex-row lg:py-0 lg:opacity-100"
  >
    @for (link of links; track link.title) {
      @if (link.external) {
        <a
          (click)="closeMenu()"
          [href]="link.path"
          target="_blank"
          class="cursor-pointer text-gray-500 transition hover:text-gray-700"
        >
          {{ link.title }}
        </a>
      } @else {
        <span
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="link.path"
          (click)="closeMenu()"
          class="cursor-pointer text-gray-500 transition hover:text-gray-700"
          routerLinkActive="font-semibold !text-black border-b border-primary border-0"
        >
          {{ link.title }}
        </span>
      }
    }

    <app-button
      (click)="closeMenu()"
      color="primary"
      routerLink="/event-anlegen"
    >
      Event anlegen
    </app-button>
  </div>
</div>

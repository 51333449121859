import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import {
  isPlatformBrowser,
  NgClass,
  NgForOf,
  NgOptimizedImage,
} from '@angular/common';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonComponent } from '@shared/_components/button/button.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    SvgIconComponent,
    ButtonComponent,
    NgClass,
    NgOptimizedImage,
  ],
  templateUrl: './footer.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnDestroy {
  wasOpenedByUser = signal(false);
  isFooterHidden = signal(true);
  isHandleHidden = signal(true);

  links: {
    path: string;
    external?: boolean;
    title: string;
  }[] = [
    {
      title: 'Veranstaltungen',
      path: '',
    },
    {
      title: 'Über uns',
      path: '/über-uns',
    },
    {
      title: 'Kontakt',
      path: 'mailto:info@events-069.de',
      external: true,
    },
    {
      title: 'Event erstellen',
      path: '/event-anlegen',
    },
  ];

  bottomLinks = [
    {
      title: 'Impressum',
      path: '/impressum',
    },
    {
      title: 'Datenschutz',
      path: '/datenschutz',
    },
    {
      title: 'Nutzungsbedingungen',
      path: '/agb',
    },
  ];

  toggleFooter() {
    this.isFooterHidden.update((value) => !value);
    this.wasOpenedByUser.set(true);
  }

  private resizeObserver?: ResizeObserver;
  private prevScrollHeight = 0;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    if (isPlatformBrowser(platformId)) {
      this.resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.contentRect.height !== this.prevScrollHeight) {
            this.onScroll();
          }
          this.prevScrollHeight = entry.contentRect.height;
        });
      });

      this.resizeObserver.observe(document.body);
    }
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }

  @HostListener('window:scroll')
  @HostListener('window:resize')
  onScroll() {
    // force show footer if there is no scrollbar
    if (window.innerHeight >= document.body.scrollHeight) {
      this.isHandleHidden.set(false);
      return;
    } else {
      this.isHandleHidden.set(true);
    }

    if (window.scrollY < 200 && !this.wasOpenedByUser()) {
      this.isFooterHidden.set(true);
    }
    this.isHandleHidden.set(window.scrollY < 200);
  }

  openCookieSettings() {
    // @ts-ignore
    CookieFirst?.openPanel();
  }
}

import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { NavbarComponent } from '@shared/_components/navbar/navbar.component';
import { FooterComponent } from '@shared/_components/footer/footer.component';
import { BadgeComponent } from '@shared/_components/badge/badge.component';
import { filter, map, mergeMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { DateSelectorService } from '@shared/_components/date-selector/date-selector.service';

import dayjs from 'dayjs/esm';
import 'dayjs/esm/locale/de';
import isSameOrAfter from 'dayjs/esm/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';
import isToday from 'dayjs/esm/plugin/isToday';
import isTomorrow from 'dayjs/esm/plugin/isTomorrow';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.locale('de');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    BadgeComponent,
    AsyncPipe,
  ],
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent {
  routeData$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.activatedRoute),
    map((route) => {
      while (route.firstChild) route = route.firstChild;
      return route;
    }),
    filter((route) => route.outlet === 'primary'),
    mergeMap((route) => route.data),
  );

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    dateSelectorService: DateSelectorService,
  ) {
    dateSelectorService.selectDate(dateSelectorService.getToday());
  }
}
